// Class generator mixin
@mixin helper-class-generator($selector, $rule, $value) {
  #{$selector} {
    #{$rule}: $value; } }

// Helper Classes
@include helper-class-generator('.it-is-fullwidth', width, 100%);
@include helper-class-generator('.has-no-top-margin', margin-top, 0 !important);
@include helper-class-generator('.has-text-teal', color, $navbar-links-teal);
@include helper-class-generator('.has-text-purple', color, $navbar-links-purple);
@include helper-class-generator('.has-text-taa-grey', color, $theme-switch-grey);
@include helper-class-generator('.has-text-yellow', color, $login-yellow);
@include helper-class-generator('.is-slightly-bigger', font-size, 1.125rem);
@include helper-class-generator('.has-background-teal', background-color, $navbar-links-teal);
@include helper-class-generator('.has-background-purple', background-color, $box-bg-color);
@include helper-class-generator('.has-medium-top-margin', margin-top, .75rem);
@include helper-class-generator('.has-color-link', color, $link);

.is-scrollable-clamped {
  max-height: 12em;
  overflow-y: scroll; }
