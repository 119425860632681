.modal {
  .modal-card {
    .modal-card-head,
    .modal-card-body,
    .modal-card-foot {
      background-color: $contact-purple; }

    .modal-card-head {
      border-bottom: 0;

      .delete {
        align-self: flex-start; }

      p {
        &.modal-card-title {
          color: $link;

          .help {
            &.restriction {
              color: $home-grey; } } } } }

    .modal-card-foot {
      border-top: 0;

      .submitButton {
        color: $white;

        &[disabled] {
          background-color: $rich-black;
          border-color: transparent; } }

      .modal-cancel {
        background-color: $timeline-green;
        border-color: transparent;
        color: $rich-black;
        font-size: 1.4rem;
        max-height: 39px; } }

    .modal-card-body {
      color: $home-grey;

      progress {
        background-color: $home-grey;
        border-radius: 0; }

      input,
      textarea {
        border-top: 0;
        border-left: 0;
        border-right: 0; }

      .field {
        padding-bottom: 2rem;
        position: relative;

        &:last-child {
          padding-bottom: 0; }

        .label {
          bottom: 0;
          position: absolute;
          left: 0; }

        .help {
          float: right; } } } } }
