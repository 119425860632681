$navbar-height: 6rem;
$navbar-item-img-max-height: 4rem;
$footer-padding: .75rem;
$navbar-item-hover-background-color: $transparent;
$navbar-dropdown-background-color: $transparent;
$navbar-item-active-color: $navbar-links-teal;
$navbar-item-hover-color: $navbar-links-teal;
$navbar-dropdown-item-hover-color: $navbar-links-purple;
$navbar-dropdown-item-active-color: $navbar-links-teal;
$navbar-dropdown-border-top: 1px solid $navbar-links-teal;
$navbar-dropdown-background-color: $feature-purple-bg;
$navbar-dropdown-item-hover-background-color: $footer-bg-purple;
$family-primary: rubrik-edge-new, sans-serif;
$family-secondary: 'BioRhyme', serif;
$input-border-color: $home-grey;
$input-background-color: $transparent;
$input-color: $theme-switch-grey;
$input-focus-border-color: $navbar-links-teal;
$input-icon-color: $theme-switch-grey;
$label-color: $theme-switch-grey;
$label-weight: 400;
$title-color: $navbar-links-teal;
$body-color: $theme-switch-grey;
$tabs-link-padding: 1rem;
$tabs-link-active-color: $navbar-links-purple;

$rhap_theme-color: $link;
$rhap_background-color: transparent;
$rhap_bar-color: $navbar-links-purple;
$rhap_time-color: $link;

$cdn-url: 'https://cdn.taamusic.com';

@function get-asset($local-path) {
  @return url($cdn-url + $local-path); }
