.not-found-page {

  h1 {
    font-size: 8rem;
    font-weight: 300;
    line-height: 1; }

  p {
    font-size: 2rem;
    margin: .5rem 0;

    &.gradient-text {
      font-size: 3rem; } } }
