.timeline-wrapper {
  @media screen and ( max-width: 490px ) {
    padding: 0 1.25rem; }

  @media screen and ( max-width: 1199px ) {
    &.column {
      margin-bottom: 4rem;
      width: 100%; } }

  .timeline {
    position: relative;

    > div {
      position: relative;
      width: 50%;

      @media screen and ( max-width: 490px ) {
        margin-bottom: 1.5rem;
        width: 100%; }

      &::after {
        background-color: $timeline-green;
        border-radius: 50%;
        display: block;
        content: '';
        position: absolute;
        top: 12px;
        height: 24px;
        width: 24px; } }

    &::after {
      background-color: $timeline-green;
      content: '';
      display: block;
      height: 100%;
      left: 50%;
      margin-top: 1.75rem;
      margin-bottom: .75rem;
      margin-left: -3px;
      position: absolute;
      top: 0;
      width: 6px;

      @media screen and ( max-width: 490px ) {
        margin-left: 0;
        left: 9px; } }


    .timeline__event--left {
      padding-right: 2rem;
      text-align: right;

      @media screen and ( max-width: 490px ) {
        padding-left: 3.25rem;
        padding-right: 0;
        text-align: left; }

      &::after {
        right: -12px;

        @media screen and ( max-width: 490px ) {
          left: 0;
          right: auto; } } }

    .timeline__event--right {
      padding-left: 2rem;
      left: 50%;

      @media screen and ( max-width: 490px ) {
        padding-left: 3.25rem;
        left: 0; }

      &::after {
        left: -12px;

        @media screen and ( max-width: 490px ) {
          left: 0; } } }

    .timeline__event-year {
      color: $link;
      font-size: 1.75rem;

      @media screen and ( max-width: 1409px ) {
        font-size: 1.5rem; } }

    .timeline__event-title {
      color: $link;
      font-size: 1.5rem;

      @media screen and ( max-width: 1409px ) {
        font-size: 1.25rem; } } } }

.about-page {
  padding-bottom: 5rem;

  @media screen and ( max-width: 1199px ) {
    flex-direction: column;
    padding-bottom: 3rem; }

  .about-content {
    padding-left: 4rem;

    @media screen and ( max-width: 1199px ) {
      padding-left: 0;
      padding: 0 1.25rem;
      width: 100%; } }

  .features-box {

    @media screen and ( max-width: 1199px ) {
      flex-direction: column;
      margin-bottom: 1rem;
      text-align: center; }

    .features-box__icon-wrapper {

      @media screen and ( max-width: 1199px ) {
        margin-bottom: 1rem;
        margin-right: 0;
        width: 100%; } } } }

