.playlist-selector {
  p {
    cursor: pointer;
    padding: .5rem 1rem;
    transition: background-color .2s, color .2s;

    &.is-selected {
      background-color: $rich-black;
      color: $link;

      &:hover {
        color: $link; } }

    &:hover {
      color: $timeline-green; } }

  .playlist-selector__list-wrapper {
    height: 10rem;
    overflow: auto; } }
