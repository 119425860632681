.back-to-top {
  background: $theme-switch-ball-base-background;
  background: linear-gradient(0deg, $theme-switch-ball-gradient-end 0%, $theme-switch-ball-gradient-start 100%);
  border: 0;
  border-radius: 50%;
  color: $rich-black;
  cursor: pointer;
  height: 3.125rem;
  margin-right: .625rem;
  opacity: 1;
  position: fixed;
  right: 0;
  top: 50%;
  transition: opacity .2s;
  width: 3.125rem;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;

  svg {
    position: absolute;
    top: -4%;
    left: 50%;
    transform: translateX(-50%); }

  @media screen and (max-width: 480px) {
    top: 90%; }

  &.hidden {
    opacity: 0; } }
