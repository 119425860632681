.wip-banner {
  position: fixed;
  top: 6rem;
  left: -20%;
  width: 140%;
  z-index: 10;
  overflow: hidden;
  white-space: pre;
  text-align: center;
  padding: 0.25em;

  background-color: saturate($warning, 25%);
  color: $black;

  transform: rotate(1.75deg);

  pointer-events: none; }
