.contact-form-type {
  @media screen and ( max-width: 768px ) {
    display: flex;
    flex-direction: column-reverse; } }

.contactFormWrapper {
  background-color: $contact-purple;

  @media screen and ( max-width: 768px ) {
    margin: 3rem 0;

    &.contactFormWrapper--no-top-margin {
      margin: 0 0 3rem 0; }

    &.contactFormWrapper--no-bottom-margin {
      margin: 3rem 0 0; } }

  input,
  textarea {
    border-top: 0;
    border-left: 0;
    border-right: 0; }

  .submitButton {
    margin-top: 3rem; }

  .contactFormWrapper__blue-form-msg {
    background-color: rgba(255,255,255,.1);
    color: $link;
    font-size: 1.4rem;
    padding: 1.5rem;
    text-align: center;
    text-transform: uppercase;

    @media screen and ( max-width: 375px ) {
      font-size: 1.2rem;
      padding: 1rem; } }

  .contactFormWrapper__form-title {
    color: $feature-purple-bg;
    font-size: 1.5rem; }

  .contactFormWrapper__inner-wrapper {
    padding: 4rem 3rem;

    @media screen and ( max-width: 480px ) {
      padding: 4rem 1.5rem; } }

  .field {
    padding-bottom: 2rem;
    position: relative;

    &:last-child {
      padding-bottom: 0; }

    label {
      bottom: 0;
      position: absolute;
      left: 0; }

    .help {
      float: right; } } }

.contactContent {
  text-align: center;

  .contactContent__blue-paragraph {
    color: $link;
    font-size: 1.5rem;
    margin-bottom: 3rem;

    @media screen and ( max-width: 480px ) {
      font-size: 1.3rem;
      margin-bottom: 2.5rem; } }

  svg {
    margin-bottom: 1rem;

    @media screen and ( max-width: 480px ) {
      font-size: 3.5rem; } }

  .contactContent__phone {
    color: $navbar-links-purple;
    font-size: 2rem;

    @media screen and ( max-width: 480px ) {
      font-size: 1.75rem; } } }
