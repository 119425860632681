* {
  outline: none;
  overscroll-behavior: none; }

html {
  -webkit-text-size-adjust: none; }

#root {
  overflow: hidden;
  width: 100vw; }

.hero {
  &.is-fullheight-with-navbar {

    .hero-body {

      &.align-to-start {
        align-items: flex-start; } } } }

.gradient-text {
  background: $theme-switch-ball-base-background;
  background: linear-gradient(90deg, $theme-switch-ball-gradient-start 0%, $theme-switch-ball-gradient-end 100%);
  background-clip: text;
  -webkit-text-fill-color: transparent; }

svg {
  &.gradient-icon-vertical * {
    fill: url('#main-icon-gradient'); }

  &.gradient-icon-vertical-variant * {
    fill: url('#main-icon-gradient-variant'); } }

.has-no-gap {
  &.divider {
    &:not(.is-right) {
      &::after {
        margin-left: 0; } }

    &:not(.is-left) {
      &::before {
        margin-right: 0; } } } }

.submitButton {
  background-color: $feature-purple-bg;
  border: 0;
  border-right: 2.438rem solid $button-purple;
  color: $white;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.4rem;
  max-height: 2.438rem;
  padding: 0;
  position: relative;

  &:hover {
    border-color: $button-purple;
    color: $white; }

  span {
    display: inline-block;
    padding: .25rem 2.5rem;

    svg {
      color: $white;
      max-height: 100%;
      position: absolute;
      right: -1.781rem;
      top: 0; } }

  svg {
    color: $white;
    max-height: 100%;
    position: absolute;
    right: -1.781rem;
    top: 0; } }

// .submitButton
//   background-color: $navbar-links-teal
//   border: 1px solid $navbar-links-teal
//   color: $dark-theme-bg

//   &:hover
//     background-color: $navbar-links-teal
//     border: 1px solid $navbar-links-teal
//     color: $dark-theme-bg

//   &.is-loading
//     background-color: $navbar-links-teal
//     border: 1px solid $navbar-links-teal
//     color: $dark-theme-bg

* {
  scrollbar-width: thin;
  scrollbar-color: $rich-black $theme-switch-ball-base-background; }

::-webkit-scrollbar {
  width: .688rem; }

::-webkit-scrollbar-track {
  background: linear-gradient(135deg, $theme-switch-ball-gradient-start 0%, $theme-switch-ball-gradient-end 100%);
  border-radius: .375rem; }

::-webkit-scrollbar-thumb {
  background-color: $rich-black;
  border-radius: .375rem;
  border: 0; }

progress.progress {
  background-color: $home-grey;
  border-radius: 0;
  min-width: 1em; }

.msg-failure {
  color: $navbar-links-purple; }

.msg-success {
  color: $link; }

.msg-failure,
.msg-success {
  font-size: 2rem;
  font-weight: 300; }

.dark-theme {
  strong {
    color: $grey-light; }

  .subtitle {
    color: $grey; } }

.hero.is-halfheight .hero-body, .hero.is-fullheight .hero-body, .hero.is-fullheight-with-navbar .hero-body {
  &:not(.hero-centered) {
    align-items: flex-start; } }
