.terms-page,
.privacy-page {
  padding-bottom: 2.5rem;

  h1 {
    color: $navbar-links-purple;
    font-weight: 300; }

  h3 {
    color: $link;
    font-weight: 300; }

  strong {
    color: $home-grey; }

  a {
    color: $navbar-links-purple;
    transition: color .2s;

    &:hover {
      color: $timeline-green; } } }
