.commercials-form {
  max-width: 56em;
  margin: 0 auto; }

.commercials-form__calendar-title {
  font-size: 1.5em;
  margin-bottom: 0.25em;
  font-weight: bold;
  color: $link; }

.commercials-form__calendar {
  border: 1px solid $border;
  margin: 0 auto 1em;

  display: flex;
  flex-direction: column;

  width: 100%; }

.commercials-form__isci-title {
  padding: 0.5em 0.5em 0;
  font-size: 1.5em;
  line-height: 1; }

.commercials-form__isci-commercial-name {
  padding: 0.25em 1em 1em;
  font-weight: bold;
  font-size: 0.75em;
  line-height: 1;
  border-bottom: 1px solid $border; }

.commercials-form__columns {
  display: flex; }

.commercials-form__logged,
.commercials-form__missed {
  flex: 1 1 100%; }

.commercials-form__missed {
  border-left: 1px solid $border; }

.commercials-form__day {
  flex: 1 1 100%;

  &:not(:first-child) {
    border-left: 1px solid $border; } }

.commercials-form__column-title {
  font-weight: bold;
  line-height: 1;

  padding: 0.5em 1em;

  border-bottom: 1px solid $border; }

.commercials-form__occurrence {
  padding: 0.25em 0.5em;
  // text-align: right

  border-bottom: 1px solid $border;

  &:last-child {
    margin-bottom: -1px; }

  strong {
    color: inherit; } }

.commercials-form__occurrence-title {
  line-height: 1;
  text-align: left;
  font-size: 1em;
  font-weight: bold;
  padding: 0.25em 0; }

.commercials-form__occurrence-isci {
  line-height: 1;
  // font-weight: bold
  font-size: 0.65em;
 }  // padding-top: 0.5em

.commercials-form__occurrence-duration {
  line-height: 1;
  // font-weight: bold
  font-size: 0.65em;
  padding-top: 0.25em; }

.commercials-form__occurrence-comment-title {
  line-height: 1;
  font-size: 0.65em;
  margin: 0.75em 0 0;
  font-weight: bold; }

.commercials-form__occurrence-comment {
  // line-height: 1
  margin: 0 0 0.25em;
  font-size: 0.85em; }

.commercials-form__isci-list-title {
  font-size: 1.5em;
  font-weight: bold;
  color: $link;
  margin-bottom: 0.25em; }

.commercials-form__isci-list-body {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap; }

.commercials-form__isci-list-entry {
  flex: 0 0 calc(50% - 0.25em);
  width: calc(50% - 0.25em);
  padding: 0.5em;
  margin-bottom: 0.5em;

  &:nth-child(odd) {
    margin-right: 0.5em; }

  border: 1px solid $border; }

.commercials-form__isci-list-entry-title {
  font-size: 1.5em;
  line-height: 1;
  margin-bottom: 0.25em; }

.commercials-form__isci-list-entry-name {
  font-size: 0.75em;
  line-height: 1;
  font-weight: bold;
 }  // margin-top: 0.25em

.commercials-form__isci-list-entry-occurrences {
  font-size: 0.75em;
  line-height: 1;
  margin: 0.25em 0 0.5em; }

.commercials-form__isci-list-top-row {
  display: flex;
  justify-content: space-between; }

.commercials-form__isci-list-top-row-middle {
  text-align: right; }

.commercials-form__isci-list-entry-duration {
  font-size: 0.75em;
  line-height: 1; }

.commercials-form__isci-list-entry-language {
  font-size: 0.75em;
  line-height: 1;
  margin-top: 0.25em; }

.commercials-form__isci-list-top-row-middle {
  align-self: center;
 }  // height: 1.5em

.commercials-form__isci-list-entry-body {
  display: flex;
  justify-content: space-between;
  margin-top: 0.5em; }

.commercials-form__isci-list-entry-comment-title {
  font-size: 0.75em;
  line-height: 1;
  font-weight: bold;
  margin-bottom: 0.25em; }

.commercials-form__isci-list-entry-occurrence-list {
  width: 12em;
  flex-shrink: 0; }

.commercials-form__isci-list-entry-occurrence-list-title {
  font-size: 0.75em;
  line-height: 1;
  font-weight: bold;
  margin-bottom: 1em; }

.commercials-form__isci-list-entry-run-day {
  margin-top: 0.25em; }

.commercials-form__isci-list-entry-run-day-day {
  font-size: 0.75em;
  line-height: 1;
  font-weight: bold; }
