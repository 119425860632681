.hero-body {
  &.home-body {
    padding: 8rem 1.5rem;

    @media screen and (max-width: 560px) {
      padding: 3rem 1.5rem; }

    @media screen and (max-width: 450px) {
      display: block !important; }

    @media screen and (max-width: 360px) {
      padding: 1rem 1.5rem; } } }

.home-content {
  .home-main-message {
    font-size: 9rem;
    font-weight: 200;
    line-height: 1.2;

    @media screen and (max-width: 1220px) {
      font-size: 7rem; }

    @media screen and (max-width: 910px) {
      font-size: 5rem; }

    @media screen and (max-width: 670px) {
      font-size: 4rem; }

    @media screen and (max-width: 560px) {
      font-size: 3.25rem; }

    @media screen and (max-width: 440px) {
      font-size: 3rem; } }

  h1 {
    font-weight: 800;

    @media screen and (max-width: 1220px) {
      font-size: 1.75rem !important; }

    @media screen and (max-width: 910px) {
      font-size: 1.5rem !important; }

    @media screen and (max-width: 670px) {
      font-size: 1.25rem !important; } }

  .home-search {
    background: transparent;
    border: 0;
    border-bottom: 2px solid $home-grey;
    color: $theme-switch-grey;
    font-weight: 200;
    outline: none;
    width: 100%;

    &::-webkit-input-placeholder {
      font-style: italic; }

    &:-moz-placeholder {
      font-style: italic; }

    &::-moz-placeholder {
      font-style: italic; }

    &:-ms-input-placeholder {
      font-style: italic; }

    @media screen and (max-width: 910px) {
      font-size: 2rem !important; }

    @media screen and (max-width: 670px) {
      font-size: 1.5rem !important; }

    @media screen and (max-width: 560px) {
      font-size: 1.25rem !important;
      padding-left: 1.25rem !important;
      padding-right: 1.25rem !important; } } }

.home-content__upper-part {
  height: calc(100vh - 11rem);
  position: relative;
  width: 100%;

  @media screen and (max-width: 560px) {
    height: calc(100vh - 6rem); }

  @media screen and (max-width: 360px) {
    height: calc(100vh - 4rem); }

  .main-svg-wrapper {
    margin: 14rem -1.5rem 0;

    @media screen and ( max-height: 1050px ) and (orientation: landscape) {
      margin: 8rem -1.5rem 0; }

    @media screen and ( max-height: 900px ) and (orientation: landscape) {
      margin: 4rem -1.5rem 0; }

    @media screen and ( max-height: 900px ) and (orientation: portrait) {
      margin: 10rem -1.5rem 0; }

    @media screen and ( max-height: 730px ) and (orientation: portrait) {
      margin: 7rem -1.5rem 0; }

    @media screen and ( max-height: 600px ) and (orientation: portrait) {
      margin: 4rem -1.5rem 0; }

    object {
      @media screen and ( max-width: 1200px ) {
        transform: scale(2); }

      @media screen and ( max-width: 730px ) {
        transform: scale(2.5); }

      @media screen and ( max-width: 600px ) {
        transform: scale(3.5); }

      @media screen and ( max-width: 480px ) {
        transform: scale(4.5); } } } }

.first-slide {
  position: relative; }

.second-slide {
  position: relative;

  @media screen and (max-width: 1024px) {
    .columns {
      display: block; }

    .is-two-fifths,
    .is-6 {
      width: 100%; }

    .is-offset-1 {
      margin-left: 0; } } }

.scroll-next-page {
  background: transparent;
  border: 0;
  border-bottom: 2px solid $home-grey;
  bottom: 8rem;
  color: $home-grey;
  cursor: pointer;
  left: 50%;
  position: absolute;
  transform: translateX(-50%) translateY(-50%);

  @media screen and (max-width: 768px) {
    bottom: 5rem;
    font-size: 1rem !important; }

  @media screen and ( max-height: 950px ) {
    bottom: 5rem; }

  &::after {
    background-color: $home-grey;
    bottom: -22px;
    content: '';
    display: block;
    height: 20px;
    left: calc(50% - 1px);
    position: absolute;
    width: 2px; } }

.big-number {
  font-size: 6.5rem;
  font-weight: 200;
  line-height: 1.3;

  @media screen and (max-width: 1409px) {
    font-size: 5rem; }

  @media screen and (max-width: 1240px) {
    font-size: 4rem; }

  @media screen and (max-width: 1024px) {
    font-size: 6.5rem; }

  @media screen and (max-width: 565px) {
    font-size: 5rem; }

  @media screen and (max-width: 450px) {
    font-size: 4rem; }

  @media screen and (max-width: 374px) {
    font-size: 3rem; } }

.metric-label {
  font-weight: 800;
  letter-spacing: 4px;

  @media screen and (max-width: 1409px) {
    font-size: 1.5rem !important; }

  @media screen and (max-width: 1240px) {
    font-size: 1.2rem !important; }

  @media screen and (max-width: 1024px) {
    font-size: 2rem !important; }

  @media screen and (max-width: 565px) {
    font-size: 1.5rem !important; }

  @media screen and (max-width: 450px) {
    font-size: 1.2rem !important; }

  @media screen and (max-width: 374px) {
    font-size: 1rem !important; } }

.features-box__title {
  @media screen and (max-width: 1409px) {
    font-size: 1.2rem !important; }

  @media screen and (max-width: 1024px) {
    font-size: 1.5rem !important; }

  @media screen and (max-width: 565px) {
    font-size: 1.2rem !important; } }

.features-box__icon-wrapper {
  @media screen and (max-width: 565px) {
    margin-right: .5rem !important; }

  @media screen and (max-width: 375px) {
    margin-right: 0 !important; }

  svg {
    @media screen and (max-width: 1409px) {
      font-size: 3.65rem; }

    @media screen and (max-width: 1024px) {
      font-size: 5rem; }

    @media screen and (max-width: 565px) {
      font-size: 3.65rem; }

    @media screen and (max-width: 450px) {
      font-size: 3rem; }

    @media screen and (max-width: 375px) {
      font-size: 2.75rem; } } }


.block {
  &.metrics-type {
    margin-bottom: 2.5rem; } }

.features-box__text {
  p {
    margin-bottom: 1rem;

    a {
      color: $navbar-links-purple;
      transition: color .2s;

      &:hover {
        color: $timeline-green; } } }

  @media screen and (max-width: 1409px) {
    font-size: 1rem !important; }

  @media screen and (max-width: 1024px) {
    font-size: 1.125rem !important; }

  @media screen and (max-width: 565px) {
    font-size: 1rem !important; } }

.features-box {
  margin-bottom: 5rem;

  @media screen and (max-width: 1409px) {
    margin-bottom: 4rem; }

  @media screen and (max-width: 1240px) {
    margin-bottom: 3rem; }

  @media screen and (max-width: 1024px) {
    margin-bottom: 4rem; }


  &:last-child {
    margin-bottom: 0;

    @media screen and (max-width: 370px) {
      margin-bottom: 3rem; } }

  .features-box__icon-wrapper {
    flex-shrink: 0;
    margin-right: 2rem;
    margin-top: 1rem;
    text-align: center;
    width: 100px; } }

.features-box__cta {
  background-color: $feature-purple-bg;
  border: 0;
  cursor: pointer;
  padding-left: 7px;
  padding-right: 0;
  position: relative;
  width: 180px;

  @media screen and (max-width: 374px) {
    width: 150px; }

  &::after {
    background-color: $feature-purple-accent;
    content: '';
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 7px; } }

.need-help-section {
  position: absolute;
  font-size: 1.75rem;
  left: 0;
  width: 100%;

  @media screen and ( max-width: 1410px ) {
    font-size: 1.25rem; }

  @media screen and ( max-height: 660px ) {
    display: none; }

  a {
    color: $navbar-links-purple;

    &:hover {
      color: $link; } }

  .need-help__trigger {
    cursor: pointer;
    position: absolute;
    left: 0;
    top: 0;
    transition: top .2s, opacity .2s;
    width: 100%; }

  .need-help__el {
    transition: transform .2s, opacity .2s;
    transform: translateY(100%);

    @media screen and ( max-width: 1023px ) {
      opacity: 0; } }

  &.show-help {
    .need-help__trigger {
      top: -100%; }

    .need-help__el {
      transform: translateY(0%);

      @media screen and ( max-width: 1023px ) {
        opacity: 1; } } } }


