.explore-page {
  flex-grow: 1;
  flex-shrink: 1;
  margin: 0 auto;
  max-width: 1540px;
  position: relative;
  width: auto;

  @media screen and ( max-width: 768px ) {
    min-height: 100vh; }



  .tabs {
    ul {
      padding: 0 0 2px;
      position: relative;

      &::before {
        background: $theme-switch-ball-base-background;
        background: linear-gradient(-90deg, $theme-switch-ball-gradient-end 0%, $theme-switch-ball-gradient-start 100%);
        bottom: 0;
        content: '';
        display: block;
        height: 2px;
        left: 0;
        position: absolute;
        width: 100%; }

      li {
        font-family: $family-secondary;
        font-size: 1rem;
        text-transform: uppercase;
        width: 50%;

        &.is-active {
          a {
            color: $navbar-links-purple;

            &::after {
              background-color: $navbar-links-purple;
              bottom: -1px;
              content: '';
              display: block;
              height: 2px;
              left: 0;
              position: absolute;
              width: 100%; } } }

        a {
          border: 0;
          color: $home-grey;
          font-weight: 400;
          letter-spacing: 2px;
          position: relative;

          &:hover {
            color: $timeline-green; } } } } } }

.explore__search {
  .explore__search-input-wrapper {
    margin: 5rem 0;
    position: relative;
    text-align: center;

    @media screen and ( max-width: 3840px ) and ( max-height: 850px ) {
      margin: 2rem 0; }

    @media screen and ( max-width: 1024px ) {
      margin: 2rem 0;
      padding: 0; }

    .explore__search-input {
      background-color: transparent;
      border: 0;
      border-bottom: 1px solid $home-grey;
      color: $link;
      font-size: 1.5rem;
      font-weight: 300;
      outline: none;
      padding: .75rem 1.5rem;
      width: 66%;

      &::-webkit-input-placeholder {}
      font-style: italic;

      &:-moz-placeholder {
        font-style: italic; }

      &::-moz-placeholder {
        font-style: italic; }

      &:-ms-input-placeholder {
        font-style: italic; }

      @media screen and ( max-width: 1024px ) {
        width: 100%; } }

    button {
      background-color: transparent;
      border: 0;
      bottom: .75rem;
      color: $navbar-links-purple;
      cursor: pointer;
      font-size: 1.1rem;
      right: 1.5rem;
      position: absolute;
      transition: color .2s;

      &:hover {
        color: $timeline-green; }

      &.clear-filters {
        align-items: center;
        background-color: $feature-purple-accent;
        border-radius: 50%;
        color: $link;
        display: none;
        font-size: 1.1rem;
        float: right;
        padding: 0;
        height: 1.75rem;
        justify-content: center;
        width: 1.75rem;

        @media screen and ( max-width: 1024px ) {
          display: flex; } } } }

  .explore__search-results-wrapper {
    height: calc(100vh - 37rem);
    overflow: auto;
    padding: 1.5rem;

    @media screen and ( max-width: 3840px ) and ( max-height: 850px ) {
      height: calc(100vh - 25rem); }

    @media screen and ( max-width: 1024px ) {
      height: 100vh; }

    @media screen and ( max-width: 768px ) {
      padding: 0; } } }

.loadingSpinner {
  text-align: center;
  width: 100%; }

.hero-body {
  &.home-body {
    &.explore-body {
      padding: 5rem 2rem;

      @media screen and ( max-width: 3840px ) and ( max-height: 850px ) {
        padding: 2rem; }

      @media screen and ( max-width: 1024px ) {
        padding: 0 2rem 2rem; }

      @media screen and ( max-width: 360px ) {
        padding: 0 1rem 2rem; }

      .explore-page {

        > .columns {
          @media screen and ( max-width: 1024px ) {
            flex-direction: column-reverse; }

          > .column {
            @media screen and ( max-width: 1024px ) {
              width: 100%; } } } } } } }

.dark-theme {
  .player-filters {
    background-color: $rich-black;

    .filters {
      background-color: $rich-black; } } }

.lite-theme {
  .player-filters {
    background-color: $white;

    .filters {
      background-color: $white; } } }

.player-filters {
  @media screen and ( max-width: 1024px ) {
    bottom: 0;
    left: 0;
    margin-top: -188px;
    position: fixed;
    z-index: 3; }

  .explore__player-wrapper {
    @media screen and ( max-width: 1024px ) {
      position: relative; }

    @media screen and ( max-width: 768px ) {
      margin-bottom: 0; }

    .explore__player-albumart {
      @media screen and ( max-width: 1024px ) {
        display: none; } } }

  .filters {
    @media screen and ( max-width: 1024px ) {
      bottom: 0;
      position: fixed;
      height: calc(100vh - 6.5rem);
      overflow: auto;
      padding-bottom: 2rem;
      width: 100vw;
      right: -100%;
      transition: right .2s;
      z-index: 34;

      &.showGenres {
        right: .75rem; } } } }

.search-playlists {
  &.column {
    &.is-offset-1 {
      @media screen and ( max-width: 1805px ) {
        margin-left: 0; } } } }

