$affidavits-isci-height: 3em;

.affidavits-desktop {
  // margin: 6em // temporary

  padding-top: 1em;
  display: flex;
  font-size: 1.25rem;

  @media screen and ( max-width: 65rem ) {
    font-size: 1.9vw; }

  justify-content: center;

  & > * {
    flex-shrink: 0; }

  .button, .input {
    font-size: 1em; } }

// .affidavits-desktop__codes
//   padding-top: $affidavits-isci-height / 2

.affidavits-desktop__code-wrapper {
  height: $affidavits-isci-height;

  display: flex;
  justify-content: center;
  align-items: center;

  padding: 0 1.5em;
  position: relative; }

.affidavits-desktop__code {
  width: 12em;
  height: 2em;
  padding: 0;

  position: relative; }

.affidavits-desktop__code-done-icon {
  position: absolute;
  width: 1em;
  height: 1em;

  display: flex;
  justify-content: center;
  align-items: center;

  top: calc(50% - 0.5em);
  right: 0.5em; }

.affidavits-desktop__code-cursor-left,
.affidavits-desktop__code-cursor-right {
  position: absolute;
  top: calc(50% - 0.5em);

  width: 1em;
  height: 1em;
  font-size: 1.5em;

  display: flex;
  justify-content: center;
  align-items: center;

  pointer-events: none; }

.affidavits-desktop__code-cursor-left {
  left: 0; }
.affidavits-desktop__code-cursor-right {
  right: 0; }

.affidavits-desktop__weekday {
  height: $affidavits-isci-height;

  display: flex;

  > * {
    height: $affidavits-isci-height;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 0.5em;

    position: relative; }

  .affidavits-desktop__weekday-name {
    flex-direction: column;
    align-items: flex-start;

    width: 6em; }

  &.affidavits-desktop__weekday--highlighted {
    color: $link; } }

.affidavits-desktop__weekday-name-actual {
  line-height: 1; }

.affidavits-desktop__weekday-name-remaining {
  line-height: 1;
  font-size: 0.65em; }

.affidavits-desktop__weekday-cursor-top,
.affidavits-desktop__weekday-cursor-bottom {
  position: absolute;
  left: calc(50% - 0.5em);

  width: 1em;
  height: 1em;
  font-size: 1.5em;

  display: flex;
  justify-content: center;
  align-items: center;

  pointer-events: none; }

.affidavits-desktop__weekday-cursor-top {
  top: -0.5em; }

.affidavits-desktop__weekday-cursor-bottom {
  bottom: -0.5em; }

.affidavits-desktop__hour-input-actual,
.affidavits-desktop__minute-input-actual {
  height: $affidavits-isci-height * 0.6;
  width: 3em;
  text-align: center; }

.affidavits-desktop__am {
  margin-left: 1em; }

.affidavits-desktop__am,
.affidavits-desktop__pm {
  text-align: center; }

.affidavits-desktop__time-button {
  padding: 0;
  width: 2.4em;
  height: $affidavits-isci-height * 0.6; }

.affidavits-desktop__playbacks {
  margin-left: 2em;
  padding-left: 1em;
  border-left: 1px solid $border;

  width: 11em; }

.affidavits-desktop__playbacks-title {
  margin-bottom: 1em; }

.affidavits-desktop__playbacks-title-isci {
  line-height: 1;
  font-size: 0.75em;
  font-weight: bold; }

.affidavits-desktop__playback {
  display: flex;
  justify-content: space-between;
  margin: 0.5em 0; }

.affidavits-desktop__playback-label {
  line-height: 1.15; }

.affidavits-desktop__playback-day {
  font-size: 0.75em;
  font-weight: bold; }

.affidavits-desktop__weekday-done {
  width: 100%;
  position: relative; }

.affidavits-desktop__weekday-done-button {
  height: $affidavits-isci-height * 0.6;
  padding: 0; }
