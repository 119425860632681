.modal {
  .modal-card {
    .modal-card-body {
      white-space: pre-wrap;

      .field {
        padding-bottom: 2rem;
        position: relative;

        &:last-child {
          padding-bottom: 2rem !important; }

        label {
          bottom: 0;
          position: absolute;
          left: 0;
          text-transform: uppercase; }

        .help {
          float: right; } } } } }
