.affidavit-review {
  // max-width: 56em
  margin: 0 auto; }

.affidavit-review__isci {
  border: 1px solid $border;
  margin: 0 auto 1em;

  display: flex;
  flex-direction: column;

 }  // width: 56em

.affidavit-review__isci-title {
  padding: 0.5em 0.5em 0;
  font-size: 1.5em;
  line-height: 1; }

.affidavit-review__isci-commercial-name {
  padding: 0.25em 1em 1em;
  font-weight: bold;
  font-size: 0.75em;
  line-height: 1;
  border-bottom: 1px solid $border; }

.affidavit-review__columns {
  display: flex; }

.affidavit-review__logged,
.affidavit-review__missed {
  flex: 1 1 100%; }

.affidavit-review__missed {
  border-left: 1px solid $border; }

.affidavit-review__day {
  flex: 1 1 100%;

  &:not(:first-child) {
    border-left: 1px solid $border; } }

.affidavit-review__column-title {
  font-weight: bold;
  line-height: 1;

  padding: 0.5em 1em;

  border-bottom: 1px solid $border; }

.affidavit-review__playback {
  padding: 0.25em 0.5em;
  text-align: left;

  border-bottom: 1px solid $border;

  &:last-child {
    margin-bottom: -1px; }

  strong {
    color: inherit; } }

.affidavit-review__playback-isci {
  line-height: 1;
  font-weight: bold;
  margin-top: 0.25em; }

.affidavit-review__playback-time {
  line-height: 1;
  font-weight: bold;
  margin: 0.5em 0; }

.affidavit-review__playback-name {
  line-height: 1;
  font-weight: normal;
  font-size: 0.65em;
  padding-top: 0.5em; }

.affidavit-review__playback-duration {
  line-height: 1;
  font-weight: normal;
  font-size: 0.65em;
  padding-top: 0.5em; }

.affidavit-review__back-link {
  color: $grey;

  &:hover {
    color: $link; } }
