.affidavit-form__form {
  display: flex;
  justify-content: center;

  & > *:not(:last-child) {
    margin-right: 1em; } }

.affidavit-form__open-button {
  &, &.is-medium {
    font-size: 1em;
    margin-top: 2em; } }
