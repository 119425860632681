.filters {
  .filters__genres {
    padding: .75rem 1.1rem; }

  .filters__genre-head {
    color: $navbar-links-purple;
    font-size: 1.1rem;
    text-transform: uppercase;

    @media screen and ( max-width: 1024px ) {
      text-align: center; }

    button {
      background-color: transparent;
      border: 0;
      color: $navbar-links-purple;
      cursor: pointer;
      font-size: 1.1rem;
      transition: color .2s;

      &:hover {
        color: $timeline-green; }

      &.mobile-genre-close {
        align-items: center;
        background-color: $feature-purple-accent;
        border-radius: 50%;
        color: $link;
        display: none;
        font-size: 1.1rem;
        float: right;
        height: 1.75rem;
        padding: 0;
        justify-content: center;
        width: 1.75rem;

        @media screen and ( max-width: 1024px ) {
          display: flex; } } }

    &.filters__genre-head--tempo {
      color: $home-grey; } } }

.filter {
  padding: .4rem;

  .filter-item {
    align-items: center;
    background-color: $home-grey;
    display: flex;
    color: $white;
    cursor: pointer;
    font-size: .75rem;
    height: 52px;
    justify-content: space-between;
    text-transform: uppercase;
    transition: background-color .2s;

    &.is-remove {
      background-color: $feature-purple-bg;

      .filter-item__status {
        background-color: $feature-purple-accent; } }

    &.is-add {
      background-color: $link;

      .filter-item__status {
        background-color: $link-dark; } }

    &:hover {
      background-color: $timeline-green;

      .filter-item__status {
        background-color: $timeline-green-dark; } }


    .filter-item__title {
      padding: .25rem .5rem;
      user-select: none; }

    .filter-item__status {
      align-items: center;
      background-color: darken($home-grey, 20%);
      display: flex;
      font-size: 1rem;
      height: 52px;
      justify-content: center;
      padding: .25rem;
      width: 26px;
      transition: background-color .2s; } } }
