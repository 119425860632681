.theme-switcher {
  .theme-switcher__text {
    color: $theme-switch-grey; }

  .theme-switcher__slider {
    border: 2px solid $theme-switch-grey;
    border-radius: 1.875rem;
    height: 1.875rem;
    margin-right: .625rem;
    position: relative;
    width: 3.75rem;

    .theme-switcher__ball {
      background: $theme-switch-ball-base-background;
      background: linear-gradient(90deg, $theme-switch-ball-gradient-start 0%, $theme-switch-ball-gradient-end 100%);
      border-radius: 50%;
      height: 1.625rem;
      left: 0;
      position: absolute;
      top: 0;
      transition: left .2s;
      width: 1.625rem;

      &.dark-mode {
        left: 1.875rem; } } } }

.navbar {

  &.px-6 {
    @media screen and (max-width: 1140px) {
      padding-left: 1rem !important;
      padding-right: 1rem !important; }

    @media screen and (max-width: 1024px) {
      padding-left: 0 !important;
      padding-right: 0 !important; } }

  .navbar-item,
  .navbar-link {
    padding: .5rem .94rem;

    &.is-arrowless {
      color: $navbar-links-teal;
      padding-right: 0; }

    &.has-dropdown {
      padding-right: 0; } }


  &.dark-theme {
    background-color: $dark-theme-bg; }

  .navbar-end {
    margin-left: 0; }

  .navbar-start {
    margin-left: auto; }

  .navbar-start,
  .navbar-end {
    a {
      background-color: transparent;
      transition: color .2s;

      &:focus {
        background-color: transparent; }

      &::after {
        background-color: $navbar-links-purple;
        content: '';
        display: block;
        height: 2px;
        left: 0;
        position: absolute;
        top: 50%;
        transform: translateY(14px);
        transition: width .2s;
        width: 0%; }

      &:hover,
      &:active {
        background: transparent;
        color: $navbar-links-purple;

        &::after {
          width: 100%; } }

      &.has-text-purple {
        font-weight: 800;

        &:hover {
          color: $navbar-links-purple; }

        &:focus {
          color: $navbar-links-purple; } } } } }

.navbar-dropdown {
  left: auto;
  right: 0;
  text-align: right;
  top: 80%;

  a {
    &.navbar-item {
      color: $white;
      display: block;
      padding-right: .94rem;

      &::after {
        display: none; }

      &:hover {
        background-color: $button-purple;
        color: $white; } } } }

.navbar-brand {
  .navbar-item {
    img {
      @media screen and (max-width: 1080px) {
        width: 15.625rem; }

      @media screen and (max-width: 1024px) {
        padding-left: 1rem; }

      @media screen and (max-width: 374px) {
        width: 12.125rem; } } } }

.navbar-item {
  &.has-dropdown {
    .navbar-dropdown {
      display: none; }

    &.is-active {
      .navbar-dropdown {
        display: block; } } } }

.navbar-burger {
  height: 4.5rem;
  width: 4.5rem;

  span {
    background-color: $home-grey;
    border-radius: 10px;
    height: 3px;
    left: calc(50% - 1rem);
    width: 2rem;

    &:nth-child(1) {
      top: calc(50% - 10px); }

    &:nth-child(3) {
      top: calc(50% + 8px); } }

  &.is-active {
    span {
      &:nth-child(1) {
        transform: translateY(12px) rotate(45deg); }

      &:nth-child(3) {
        transform: translateY(-7px) rotate(-45deg); } } } }

.navbar-menu {
  &.is-active {
    background-color: $contact-purple;
    text-align: center;

    .navbar-end {
      .navbar-item {
        padding-right: .94rem;

        > a {
          padding-right: .94rem; } } }

    .theme-switcher {
      justify-content: center; }

    .navbar-dropdown {
      background-color: $box-bg-color;
      text-align: center; }

    .navbar-link,
    .navbar-item {
      padding: .75rem .94rem;

      &::after {
        display: none; } } }

  .navbar-end {
    width: 18.75rem;

    @media screen and ( max-width: 1310px ) {
      width: auto; } } }

.navbar-brand {
  &.is-active {
    background-color: $contact-purple; } }



