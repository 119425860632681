.explore__track-wrapper {

  &.explore__track-wrapper--mobile {
    border-bottom: 1px solid $mobile-border-track;
    justify-content: space-between;
    display: flex;
    padding: 1.5rem 0; }

  .modal {
    .submitButton {
      padding: calc(.5rem - 1px) 1rem;

      &:hover {
        border-color: $button-purple; } } }


  &:hover {
      color: $timeline-green;
      cursor: pointer;

      .explore__track-controls {
        button {
          color: $timeline-green; }

        &:hover {
          button {
            color: $home-grey;

            &:hover {
              color: $timeline-green; } } } } }

  &.is-active {
    color: $link !important;

    .explore__track-controls {
      button {
        color: $link;
        font-weight: 800; } } }

  .explore__track-art-title {
    align-items: center;
    display: flex;

    &.explore__track-art-title--mobile {
      flex: 1;

      > div {
        display: flex;
        align-items: center; } }

    .explore__track-art-title--inner-wrapper {
      background-image: get-asset('/album-artwork/Album_Default.png');
      background-size: 100% 100%;
      display: inline-block;
      margin-right: .5rem;
      height: 32px;
      width: 32px;

      img {
        height: 32px;
        width: 32px; } } } }

.explore__track-controls {
  display: flex;
  justify-content: flex-end;

  @media screen and ( max-width: 768px ) {
    align-items: flex-end;
    justify-content: flex-end;
    flex-direction: column; }

  button {
    background-color: transparent;
    border: 0;
    color: $home-grey;
    cursor: pointer;
    font-size: 1.25rem;

    @media screen and ( max-width: 768px ) {
      font-size: 1.5rem;
      padding: 0;
      width: 27px;

      &:first-child {
        margin-bottom: .75rem; } }

    &:hover {
      color: $timeline-green;
      cursor: pointer; } } }

.explore__mobile-album-art-wrapper {
  display: inline-block;
  margin-right: 1.25rem;
  width: 66px;

  @media screen and ( max-width: 480px ) {
    display: none; } }

.explore__mobile-duration-wrapper {
  align-items: center;
  display: flex;
  width: 66px; }

.explore__track-controls--mobile {
  width: 50px;

  @media screen and ( max-width: 400 ) {
    width: 30px; } }

.explore__mobile-titles-wrapper {
  display: inline-block; }
