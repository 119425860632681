.affidavit-method-selector__method {
  width: 11em;
  height: 5em;
  margin-left: 0.5em;
  margin-right: 0.5em;
  margin-bottom: 1em;
  padding: 0.5em;
  text-align: left;

  display: inline-flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  line-height: normal; }

.affidavit-method-selector__method-title {
  font-size: 1.25em;
  font-weight: bold; }

.affidavit-method-selector__method-name {
  font-size: 0.75em;
  font-weight: normal;

  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  text-overflow: ellipsis;

  margin-top: 0.25em; }

.affidavit-method-selector__method-notice {
  font-size: 0.75em;
  font-weight: normal;

  white-space: pre-wrap;
  overflow: hidden;
  width: 100%;
  text-overflow: ellipsis;

  margin-top: 0.25em;

  .button & .icon {
    &, &:first-child:not(:last-child) {
      margin-left: 0;
      margin-right: 0; } } }
