.affidavit-submission {
  max-width: 32em;
  margin: 0 auto; }

.affidavit-submission__line {
  padding-bottom: 1em;
  margin: 0 auto 1em;
  border-bottom: 1px solid $border;

  display: flex;

  height: 3em;
  box-sizing: content-box;

  max-width: 24em; }

.affidavit-submission__line-icon {
  width: 2em;
  height: 2em;
  font-size: 1.5em;

  display: flex;
  align-items: center;
  justify-content: center; }

.affidavit-submission__line-body {
  height: 3em;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  line-height: 1.1; }

.affidavit-submission__line-isci {
  font-size: 1.25em; }

.affidavit-submission__line-error {
  font-size: 0.75em;
  font-weight: bold;

  color: $danger; }
