.login-page {

  input,
  textarea {
    border-top: 0;
    border-left: 0;
    border-right: 0; }

  .field {
    padding-bottom: 2rem;
    position: relative;

    &:last-child {
      padding-bottom: 0; }

    label {
      bottom: 0;
      position: absolute;
      left: 0; }

    .help {
      float: right; } }

  .submitButton {
    margin-top: 3rem; } }

