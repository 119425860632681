.under-construction {
  display: flex;
  align-items: center;
  justify-content: center;

  color: $warning;

  .under-construction__icon {
    margin-right: 1em; }

  .under-construction__text {
    width: 26em; }

  .under-construction__title {
    font-weight: bold;
    font-size: 2em; }

  .under-construction__description {
    color: $text; } }
