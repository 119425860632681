.explore__player-wrapper {
  .explore__player-albumart {
    text-align: center;

    .explore__player-albumart--inner-wrapper {
      align-items: center;
      background: $theme-switch-ball-base-background;
      background: linear-gradient(135deg, $theme-switch-ball-gradient-start 0%, $theme-switch-ball-gradient-end 100%);
      border: 5px solid $home-grey;
      display: inline-flex;
      justify-content: center;
      height: 138px;
      width: 138px;

      img {
        height: 128px;
        width: 128px; } } }


  .explore__player-track-title {
    font-weight: 400;
    margin: .75rem 0;

    .explore__player-track-title__mobile-filters-button {
      background: transparent;
      border: 0;
      color: $link;
      cursor: pointer;
      display: none;
      float: right;
      font-size: 1.5rem;
      margin-right: 1.5rem;
      padding: 0;

      @media screen and ( max-width: 1024px ) {
        display: block; }

      @media screen and ( max-width: 768px ) {
        font-size: 1rem; } }

    @media screen and ( max-width: 1024px ) {
      margin: 0;
      min-height: 3rem;
      padding-top: 0;
      text-align: center; }

    @media screen and ( max-width: 768px ) {
      min-height: 0;
      padding-bottom: 0; }

    p {
      color: $link;
      font-size: 1.5rem;

      @media screen and ( max-width: 768px ) {
        font-size: 1rem; } } } }

.rhap_stacked-reverse {
  .rhap_progress-section {
    display: block;

    .rhap_current-time,
    .rhap_time.rhap_total-time {
      display: inline-block;
      font-size: .75rem;
      margin-top: .5rem;
      width: 50%; }

    .rhap_time.rhap_total-time {
      text-align: right; }

    .rhap_progress-container {
      height: 15px;
      margin: 0 7.5px;

      .rhap_progress-bar {
        height: 3px;

        .rhap_progress-indicator {
          box-shadow: none;
          height: 15px;
          margin-left: 0;
          top: -6px;
          transform: translateX(-50%);
          width: 15px; } } } }

  .rhap_controls-section {
    margin-bottom: 1rem;

    .rhap_additional-controls {
      display: none; }

    .rhap_main-controls {
      width: 100%;


      .rhap_main-controls-button {
        align-items: center;
        border: 2px solid $link;
        border-radius: 50%;
        display: flex;
        cursor: pointer;
        justify-content: center;
        margin-right: 2rem;
        transition: color .2s, border-color .2s;

        @media screen and ( max-width: 390px ) {
          margin-right: 1rem; }


        &:last-child {
          margin-right: 0; }

        &:hover {
          border-color: $timeline-green;
          color: $timeline-green; }


        &.rhap_rewind-button,
        &.rhap_forward-button {
          height: 39px;
          padding: .25rem;
          width: 39px; }

        &.rhap_rewind-button {
          svg {
            transform: translateX(-.1rem); } }

        &.rhap_forward-button {
          svg {
            transform: translateX(.1rem); } }

        &.rhap_play-pause-button {
          padding: 1rem;
          height: 68px;
          width: 68px;

          svg {
            transform: translateX(.2rem);
            height: 32px;
            width: 28px; }

          &[aria-label="Pause"] {

            svg {
              transform: translateX(0); } } } } } } }

.rhap_container {
  box-shadow: none; }
