.explore__playlists-wrapper {
  padding: 0 1.5rem;

  @media screen and ( max-width: 768px ) {
    padding: 0; }


  .explore__playlists-add-wrapper {
    cursor: pointer;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 4rem;

    button {
      background-color: transparent;
      border: 0;
      color: $navbar-links-purple;
      cursor: pointer;
      font-size: 1.1rem;

      &:hover {
        color: $timeline-green; } } } }

.explore__playlists-playlist-wrapper {

  &.is-active {
    .explore__playlists-playlist-tracks {
      max-height: 1000rem; } }

  .explore__playlists-playlist-head {
    cursor: pointer;
    display: flex;
    justify-content: space-between;

    &:hover {
      .explore__playlists-playlist-title {
        color: $timeline-green; }

      .explore__playlists-playlist-controls {
        button {
          color: $timeline-green; }

        &:hover {
          button {
            color: $navbar-links-purple;

            &:hover {
              color: $timeline-green; } } } } }
    .explore__playlists-playlist-title {
      align-items: center;
      color: $navbar-links-purple;
      display: flex;
      font-size: 1.1rem;
      text-transform: uppercase;

      span {
        margin-right: .25rem; } }

    .explore__playlists-playlist-controls {
      padding-right: 1rem;

      @media screen and ( max-width: 768px ) {
        padding-right: 0; }

      button {
        background-color: transparent;
        border: 0;
        color: $navbar-links-purple;
        cursor: pointer;
        font-size: 1.25rem; } } }

  .explore__playlists-playlist-tracks {
    max-height: 0;
    overflow: hidden;
    padding-left: 1.5rem;
    transition: max-height .2s;

    @media screen and ( max-width: 400px ) {
      padding-left: 0; }


    .explore__track-wrapper {
      &:first-child {
        margin-top: 1.5rem; }

      &:last-child {
        margin-bottom: 1.5rem; } } } }

.explore__playlists-list {
  justify-content: center;

  > div {
    width: 100%; } }

