$theme-switch-grey: #808080;
$theme-switch-ball-gradient-start: rgba(0, 171, 189, 1);
$theme-switch-ball-gradient-end: rgba(214, 224, 64, 1);
$theme-switch-ball-base-background: rgb(0, 171, 189);
$navbar-links-teal: #00abbd;
$navbar-links-purple: #a23293;
$white: #fff;
$dark-theme-bg: #040000;
$home-grey: #939598;
$feature-purple-bg: #a23293;
$feature-purple-accent: #432256;
$footer-bg-purple: #3e253b;
$footer-separator-teal: #09b2b9;
$transparent: transparent;
$box-bg-color: #2f002b;
$login-yellow: #d8e040;
$contact-purple: #460B43;
$button-purple: #432256;
$rich-black: #040000;
$timeline-green: #9dc435;
$timeline-green-dark: #355514;
$dark-blue: #2e3092;
$link-dark: #003959;
$mobile-border-track: #333;

// bulma overrides
$primary: #a23293;
$link: #00abbd;
$radius: 0;

$danger: #df3805;
$warning: #e0bb40;
$success: #8de040;
