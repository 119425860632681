.week-selector__week {
  width: 11em;
  height: 5em;
  margin-left: 0.5em;
  margin-right: 0.5em;
  margin-bottom: 1em;
  padding: 0.5em;
  text-align: left;

  display: inline-flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  line-height: normal; }

.week-selector__week-title {
  font-size: 1.25em;
  font-weight: bold; }

.week-selector__back-link {
  color: $grey;

  &:hover {
    color: $link; } }

.week-selector__week-notice {
  font-size: 0.75em;
  font-weight: normal;

  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  text-overflow: ellipsis;

  margin-top: 0.25em;

  .button & .icon {
    &, &:first-child:not(:last-child) {
      margin-left: 0;
      margin-right: 0; } } }
