.footer {
  background-color: $footer-bg-purple;
  color: $navbar-links-purple;
  display: flex;
  padding: .75rem 0;

  @media screen and (max-width: 870px) {
    align-items: center;
    padding: 1rem 0; }

  @media screen and (max-width: 480px) {
    flex-direction: column-reverse; }


  .footer__btn-btt {
    background: transparent;
    border: 0;
    cursor: pointer;
    flex-shrink: 0;
    height: 100%;
    padding: 0 10rem;
    position: relative;

    @media screen and (max-width: 1064px) {
      padding: 0 4rem; }

    @media screen and (max-width: 480px) {
      padding: 1.25rem 0;
      width: 100%; }

    &::after {
      background-color: $footer-separator-teal;
      content: '';
      display: block;
      height: 100%;
      position: absolute;
      right: -1px;
      top: 0;
      width: 1px;

      @media screen and (max-width: 870px) {
        height: 300%;
        top: 50%;
        transform: translateY(-50%); }

      @media screen and (max-width: 480px) {
        height: 1px;
        left: 0;
        right: auto;
        top: 0;
        width: 100%; } } }

  .footer-nav {
    display: flex;
    font-size: 13px;
    justify-content: center;
    width: 100%;

    @media screen and (max-width: 870px) {
      font-size: 1rem; }

    ul {
      align-items: center;
      display: flex;

      @media screen and (max-width: 870px) {
        flex-direction: column; }

      li {
        margin-right: 2rem;

        @media screen and (max-width: 870px) {
          margin-bottom: .75rem;
          margin-right: 0;
          text-align: center;
          width: 100%; }

        a {
          color: $navbar-links-purple; }

        &:last-child {
          margin-right: 0;

          @media screen and (max-width: 480px) {
            margin-bottom: 1.25rem; } } } } } }

